import PropTypes from "prop-types"
import React, { useContext } from "react"
import "./header.css"
import logo from "../../images/blue-logo.png"
import image from "../../images/hair-category-cover.png"
import { categoryContext } from "../../context/categoryContext/categoryContext"
import { searchContext } from "../../context/searchContext/searchContext"
import { Link, navigate } from "gatsby"
import { userContext } from "../../context/userContext/userContext"
import { base } from "../../config/urls"

const Header = ({ siteTitle }) => {
  const searchCtx = useContext(searchContext)
  const categoryCtx = useContext(categoryContext)
  const userCtx = useContext(userContext)

  let categoryList = categoryCtx.baseCategory.map(category => (
    <>
    <li className="uk-visible@m">
      <a href="#">{category.name}</a>
      {category.subcategory.length > 0 && (
        <div className="uk-navbar-dropdown uk-navbar-dropdown-width-3">
          <div className="uk-navbar-dropdown-grid" data-uk-grid>
            <div className="uk-width-1-3@m">
              <ul className="uk-nav uk-navbar-dropdown-nav">
                <li
                  className="uk-nav-header"
                  style={{ fontWeight: "800 !important" }}
                >
                  {category.name}
                </li>
                {category.subcategory.map(subCat => (
                  <li>
                    <a
                      onClick={() => {
                        searchCtx.searchSubCategoryBaseSalon(subCat.id)
                        navigate("/listing")
                      }}
                    >
                      {subCat.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="uk-width-expand@m">
              <img src={category.pic} alt=""/>
            </div>
          </div>
        </div>
      )}
    </li>
    <li className="category-menu-mobile uk-hidden@m">
          <a class="uk-accordion-title" href="#">{category.name}</a>
          <div class="uk-accordion-content">
          {category.subcategory.length > 0 && (
            <ul class="uk-nav-sub">
              {category.subcategory.map(subCat => (
                    <li>
                      <a
                        onClick={() => {
                          searchCtx.searchSubCategoryBaseSalon(subCat.id)
                          navigate("/listing")
                        }}
                      >
                        {subCat.name}
                      </a>
                    </li>
                  ))}
            </ul>
          )}
          </div>
      </li>
    </>
  ))

  return (
    <header>
      <nav className="mobile-nav uk-hidden@m pv3 ph2 flex justify-between items-center w-100">
        <Link to={"/"}>
          <img
            src={logo}
            alt="treato company logo"
            style={{ height: "25px" }}
          />
        </Link>
        <a uk-toggle="target: #offcanvas-nav-primary" uk-icon="icon: menu; ratio: 2"></a>
      </nav>
      <nav
        id="menu"
        className="uk-navbar-container uk-navbar uk-background-default uk-visible@m ph6-m"
        data-uk-navbar
      >
        <div className="uk-navbar-left">
          <ul className="uk-navbar-nav">
            <li>
              <Link to={"/"}>
                <img
                  src={logo}
                  alt="treato company logo"
                  style={{ height: "25px" }}
                />
              </Link>
            </li>
            {categoryList}
          </ul>
        </div>

        <div className="uk-navbar-right">
          <ul className="uk-navbar-nav">
            <li>
              <a href="https://salon.treato.in/register" target="_blank" className="uk-text-secondary">
                List your Salon
              </a>
            </li>
            <li>
              {userCtx.user ? (
                <Link className="uk-text-primary" to={"/dashboard"}>
                  <span
                    data-uk-icon="icon: user"
                    className="uk-margin-small-right"
                  >
                    {" "}
                  </span>{" "}
                  Profile
                </Link>
              ) : (
                <Link className="uk-text-primary" to={"/login"}>
                  <span
                    data-uk-icon="icon: user"
                    className="uk-margin-small-right"
                  >
                    {" "}
                  </span>{" "}
                  Login/Register
                </Link>
              )}
            </li>
            <li>
              <a
                href="#offcanvas-usage"
                data-uk-toggle
                className="uk-navbar-toggle uk-hidden"
                data-uk-navbar-toggle-icon
              />
            </li>
          </ul>
        </div>
      </nav>
      <div id="offcanvas-nav-primary" uk-offcanvas="overlay: true">
        <div class="uk-offcanvas-bar uk-flex uk-flex-column">

            <ul class="uk-nav uk-nav-primary uk-nav-left uk-margin-auto-vertical">
                <li><Link to={"/"}>
                  <span
                  class="uk-margin-small-right mobile-menu-icon"
                  uk-icon="icon: home"></span>Home
                  </Link>
                </li>

                <li>
                  {userCtx.user ? (
                    <Link to={"/dashboard"}>
                      <span
                        data-uk-icon="icon: user"
                        className="uk-margin-small-right mobile-menu-icon"
                      >
                        {" "}
                      </span>{" "}
                      Profile
                    </Link>
                  ) : (
                    <Link to={"/login"}>
                      <span
                        data-uk-icon="icon: user"
                        className="uk-margin-small-right mobile-menu-icon"
                      >
                        {" "}
                      </span>{" "}
                      Login/Register
                    </Link>
                  )}
                </li>
                <li class="uk-nav-divider"></li>
                <li>
                  <ul uk-accordion>
                    {categoryList}
                  </ul>
                </li>
            </ul>

        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
